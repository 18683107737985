import React, { useState } from 'react';
import NeumetricLogoImg from "../../assets/images/neumetric-logo.png";
import ProfilePicImg from "../../assets/images/profile_pic.png";
import Eval404Img from "../../assets/images/404-eval.png";
import Eval403Img from "../../assets/images/403-eval.png";
import LoadingOvalImg from "../../assets/images/Loading_Oval.png";
import LoadingGIFImg from "../../assets/images/Loading.gif";
import LogInOval from "../../assets/images/login-oval.png";
import NoAccessEvalImg from "../../assets/images/no-access.png";
import LoadingWhiteGIFImg from "../../assets/images/Loading-white.gif";
//Logo
import AppLogoImg from "../../assets/images/app_logo.png";
//Icon
import ArcIconImg from "../../assets/images/arc-icon.png";
import ArcV1IconImg from "../../assets/images/arc-icon-v1.png";
import AuditorIconImg from "../../assets/images/auditor-icon.png";
import CentralIconImg from "../../assets/images/central-icon.png";
import ConsoleIconImg from "../../assets/images/console-icon.png";
import ContinuerIconImg from "../../assets/images/continuer-icon.png";
import ControllerIconImg from "../../assets/images/controller-icon.png";
import DocumentorIconImg from "../../assets/images/documenter-icon.png";
import EducatorIconImg from "../../assets/images/educator-icon.png";
import InvestigatorIconImg from "../../assets/images/investigator-icon.png";
import WarningIconImg from "../../assets/images/warning.png";
import RightArrowIconImg from "../../assets/images/right-arrow.png";
import DownArrowIconImg from "../../assets/images/down-arrow.png";
import DownloadActionIconImg from "../../assets/images/download-action.png";
import DownloadContrastIconImg from "../../assets/images/download-contrast.png";
import CalenderIconImg from "../../assets/images/calender-icon.png";
import TransferContrastIconImg from "../../assets/images/icon-transfer-contrast.png";
import AssignContrastIconImg from "../../assets/images/icon-assign-contrast.png";
import ReferenceLinkIconImg from "../../assets/images/reference-link.png"

//other assets
import BulkUploadXlsx from "../../assets/Tasks_Upload_Template.xlsx"

export const BulkUploadTemplate = BulkUploadXlsx;

export const LogInOvalImg = (props) => {
  return (
    <img src={LogInOval} {...props} />
  )
}
export const ProfilePic = (props) => {
  return (
    <img src={ProfilePicImg} {...props} />
  )
}
export const Eval404 = (props) => {
  return (
    <img src={Eval404Img} {...props} />
  )
}
export const Eval403 = (props) => {
  return (
    <img src={Eval403Img} {...props} />
  )
}
export const LoadingOval = (props) => {
  return (
    <img src={LoadingOvalImg} {...props} />
  )
}
export const LoadingGIF = (props) => {
  return (
    <img src={LoadingGIFImg} {...props} />
  )
}
export const LoadingWhiteGIF = (props) => {
  return (
    <img src={LoadingWhiteGIFImg} {...props} />
  )
}
//Logos
export const AppLogo = (props) => {
  return (
    <img src={AppLogoImg} {...props} />
  )
}
//Icons
export const AuditorIcon = (props) => {
  return (
    <img src={AuditorIconImg} {...props} />
  )
}
export const ArcIcon = (props) => {
  return (
    <img src={ArcIconImg} {...props} />
  )
}
export const ArcV1Icon = (props) => {
  return (
    <img src={ArcV1IconImg} {...props} />
  )
}
export const ConsoleIcon = (props) => {
  return (
    <img src={ConsoleIconImg} {...props} />
  )
}
export const DocumentorIcon = (props) => {
  return (
    <img src={DocumentorIconImg} {...props} />
  )
}
export const EducatorIcon = (props) => {
  return (
    <img src={EducatorIconImg} {...props} />
  )
}
export const InvestigatorIcon = (props) => {
  return (
    <img src={InvestigatorIconImg} {...props} />
  )
}
export const ControllerIcon = (props) => {
  return (
    <img src={ControllerIconImg} {...props} />
  )
}
export const CentralIcon = (props) => {
  return (
    <img src={CentralIconImg} {...props} />
  )
}
export const RightArrowIcon = (props) => {
  return (
    <img src={RightArrowIconImg} {...props} />
  )
}
export const DownArrowIcon = (props) => {
  return (
    <img src={DownArrowIconImg} {...props} />
  )
}
export const CalenderIcon = (props) => {
  return (
    <img src={CalenderIconImg} {...props} />
  )
}
export const NeumetricLogo = (props) => {
  return (
    <img src={NeumetricLogoImg} {...props} />
  )
}
export const CompanyLogo = (props) => {
  const { user } = props
  let [error, setError] = useState(false);
  return (
    <React.Fragment>
      {
        error ?
          <NeumetricLogo alt='company Logo' className='company' />
          :
          <img src={'https:' + (user.logo_url ? user.logo_url : (user.ent_org && user.ent_org.logo_url ? user.ent_org.logo_url : ''))} alt='company Logo' className='company' onError={() => setError(true)} />
      }
    </React.Fragment>
  )
}
export const WarningIcon = (props) => {
  return (
    <img src={WarningIconImg} {...props} />
  )
}
export const NoAccessEval = (props) => {
  return (
    <img src={NoAccessEvalImg} {...props} />
  )
}
export const ContinuerIcon = (props) => {
  return (
    <img src={ContinuerIconImg} {...props} />
  )
}
export const DownloadActionIcon = (props) => {
  return (
    <img src={DownloadActionIconImg} {...props} />
  )
}
export const DownloadContrastIcon = (props) => {
  return (
    <img src={DownloadContrastIconImg} {...props} />
  )
}
export const TransferContrastIcon = (props) => {
  return (
    <img src={TransferContrastIconImg} {...props} />
  )
}
export const AssignContrastIcon = (props) => {
  return (
    <img src={AssignContrastIconImg} {...props} />
  )
}
export const ReferenceLinkIcon = (props) => {
  return (
    <img src={ReferenceLinkIconImg} {...props} />
  )
}