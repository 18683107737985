import { put, takeEvery } from "redux-saga/effects";
import { SagaRegistry, requestHandler, axios, getUserDetails, sortByProperty, fetchS3ObjectAsBlob } from "../app";
import { commonActions } from "./slice";
import { saveAs } from 'file-saver';

function* fetchDepartments() {
  yield requestHandler(function* () {
    let res = yield axios.get('/consoles/department_list');
    res = (res.department_list || []).map((_) => ({ label: _, id: _ }));
    res.sort(sortByProperty.bind(null, 'label'))
    yield put(commonActions.setDepartments(res))
  }, "Failed to Fetch Departments", false);
}
function* fetchContinuityDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/continuers/con_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      item.responder_name = '';
      getUserDetails(item, 'responder')
      return { ...item }
    });
    yield put(commonActions.setContinuityDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* fetchIncidentDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/incidents/inc_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      item.responder_name = '';
      getUserDetails(item, 'responder')
      return { ...item }
    });
    yield put(commonActions.setIncidentDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* fetchTaskDeptResponders() {
  yield requestHandler(function* () {
    let res = yield axios.get('/task_blueprint/task_dept_responders');
    let list = res.dept_responders ? res.dept_responders : res;
    list = Array.isArray(list) ? list : [];
    list = list.map((item) => {
      getUserDetails(item.responder)
      item.responder_name = item.responder ? item.responder._listname : ''
      return { ...item }
    });
    yield put(commonActions.setTaskDeptResponders(list))
  }, "Failed to Fetch Department Responders", false);
}
function* fetchTaskPriorities() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/tasks/priority_list`);
    let list = res.priority_list ? res.priority_list : [];
    list = Array.isArray(list) ? list : [];
    list = list.map((_) => {
      return { id: _, label: _ };
    })
    yield put(commonActions.setTaskProrities(list))
  }, "Failed to load Task Priority list");
}
function* fetchTaskStatus() {
  yield requestHandler(function* () {
    const res = yield axios.get(`/tasks/status_list`);
    let list = res.status_list ? res.status_list : [];
    list = Array.isArray(list) ? list : [];
    // list = list.map((_) => {
    //   return { id: _, label: _ };
    // })
    yield put(commonActions.setTaskStatus(list))
  }, "Failed to load Task Status list");
}
function* downloadAttachment({ payload }) {
  yield requestHandler(function* () {
    const { attachment } = payload;
    const blob = yield fetchS3ObjectAsBlob(attachment.encrypt_evidence_url);
    if (blob instanceof Blob) {
      saveAs(blob, attachment.document_file_name);
    }
  }, "Failed to download attachement");
}

SagaRegistry.register(function* userSaga() {
  yield takeEvery("common/fetchDepartments", fetchDepartments);
  yield takeEvery("common/fetchTaskPriorities", fetchTaskPriorities);
  yield takeEvery("common/fetchTaskStatus", fetchTaskStatus);
  yield takeEvery("common/downloadAttachment", downloadAttachment);
  yield takeEvery("common/fetchIncidentDeptResponders", fetchIncidentDeptResponders);
  yield takeEvery("common/fetchContinuityDeptResponders", fetchContinuityDeptResponders);
  yield takeEvery("common/fetchTaskDeptResponders", fetchTaskDeptResponders);
})