import React from 'react';
import { Button as AntButton } from 'antd';
import { EllipsisOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import "./style.scss";

const ButtonContent = (props) => {
  const { icon, label, labelClassName } = props;
  return (
    <>
      {Boolean(icon) && icon}
      {Boolean(label) && <span className={`label ${labelClassName}`}>{label}</span>}
    </>
  )
}

export const Button = (props) => {
  const { label, labelClassName, type, color, className, loading, ...rest } = props;
  const noLabel = !Boolean(label) ? 'non-label' : '';
  const style = color ? { color } : {}
  return (
    <AntButton
      type={type || 'button'}
      className={`row v-ctr h-ctr basic-btn ${noLabel} ${className || ''}`}
      loading={loading}
      {...rest}>
      {
        Boolean(label) &&
        <span className={`label ${labelClassName || ''}`} style={style}>{label}</span>
      }
    </AntButton>
  );
}

export const PrimaryButton = (props) => {
  const { className, variant, ...rest } = props;
  const labelClassName = `cFFF ${rest.labelClassName || ''}`
  return <Button className={`${variant === 'lite' ? 'bg-lite' : 'bg0133CC'} ${className || ''}`} {...rest} labelClassName={labelClassName} />
}
export const MenuButton = (props) => {
  const { className, icon, iconColor, ...rest } = props;
  const btnIcon = Boolean(icon) ? icon : <EllipsisOutlined style={{ fontSize: 16, color: iconColor || '#FFF', marginTop: 8 }} />
  return <Button labelClassName='hide' icon={btnIcon} className={`btn-menu ${className || ''}`} {...rest} />
}
export const LinkButton = (props) => {
  const { href, className, variant, font, link, disabled, label, color, ...rest } = props;
  const _className = `nm-link btn line-22 ${font || ''} ${disabled ? "btn-disabled" : (variant === 'lite' ? 'bg-lite' : 'bg0133CC')} ${className || ''}`;
  return (
    Boolean(href) ?
      <a href={href} className={_className} {...rest}>
        <ButtonContent {...props} />
      </a>
      :
      <Link to={link} className={_className} {...rest}>
        <ButtonContent {...props} />
      </Link>
  )
}